<template>
    <div class="ResponsiveContentBoard">
        <div class="areaCentralInfo">
            <div class="flex wrapMainCards">
                <div :class="['flexColumn', 'mainCard', { 'fire_animation': isItFire }]">
                    <img src="@/assets/icons/grayFire.svg" alt="fire" class="mainCardIlu">
                    <span class="mainCardText">Fogo</span>
                </div>

                <div :class="['flexColumn', 'mainCard', { 'warning_animation': isItWarning }]">
                    <img src="@/assets/icons/grayWarning.svg" alt="fire" class="mainCardIlu">
                    <span class="mainCardText">Falha</span>
                </div>
            </div>

            <div class="flexColumn wrapVerifyCards">
                <div :class="['VerifyCard', 'flex', {'verifyFire_animation': isItFire}]">
                    <img src="@/assets/icons/verified.svg" alt="green_shield" class="verifyCardIlu">
                    <span class="verifyCardText" v-show="!isItFire">Fogo: Sem riscos de incêncio</span>
                    <span class="verifyCardTextFire" v-show="isItFire">Fogo: Chama detectada</span>
                </div>
                <div :class="['VerifyCard', 'flex', {'verifyWarning_animation': isItWarning}]">
                    <img src="@/assets/icons/verified.svg" alt="green_shield" class="verifyCardIlu">
                    <span class="verifyCardText" v-show="!isItWarning">Falha: Sem riscos de falha</span>
                    <span class="verifyCardTextWarning" v-show="isItWarning">Falha: Falha detectada</span>
                </div> 
            </div>

           <div class="areaInfo">
                    <div class="areaInfoCard" style="flex: 0 37.5%;">
                        <Gauge :label="'CPU'" :percent="CPU"></Gauge>
                    </div>
                    <div class="areaInfoCard" style="flex: 0 37.5%;">
                        <Gauge :label="'RAM'" :percent="MEM"></Gauge>
                    </div>
                    <div class="areaInfoCard" style="flex: 0 30%;">
                        <Thermometer :temp="temp"></Thermometer>
                    </div> 

                    <!-- <div class="areaInfoCard" style="flex: 0 30%;">
                        <label v-if="isGatewayUnlocked" class="textSmall label">Central violada</label>
                        <label v-else class="textSmall label">Central fechada</label>
                        <img v-if="isGatewayUnlocked" src="@/assets/icons/dashboard/padlockOpen.svg" alt="Open padlock" class="padlockImg">
                        <img v-else src="@/assets/icons/dashboard/padlockClose.svg" alt="Closed padlock" class="padlockImg">
                    </div>  -->
                    
                    <div class="areaInfoCard" style="flex: 0 45%;">
                        <label class="textSmall label">Armazenamento</label>
                        <ProgressBar :value="HardDisk"></ProgressBar>
                    </div>

                    <div class="areaInfoCard" style="flex: 0 40%;">
                         <label class="textSmall label" style="position: absolute">Alimentação</label>
                        <div class="redeStatus">
                            <img class="iconStatus" :src="currentBattery" alt="">
                            <div v-if="percentBattery" class="percentBattery textSmall">{{percentBattery}}</div>
                            <div v-if="batteryLevel" class="levelBattery textSmall">{{batteryLevel}}</div>
                        </div>
                        <div class="redeStatus">
                            <img class="iconStatus" :src="currentPlug" alt="">
                        </div>
                        <label class="labelBottom textSmall">{{baterryStatus}}</label> 
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {STATUS_COUNT_INDEX, SYSTEM_INFO_INDEX, NETWORK_STATUS} from '@/sys/Model/Device/Device'
    import Gauge from '@/views/content/Home/DeviceAccess/DashBoard/Widget/Gauge.vue'
    import Thermometer from '@/views/content/Home/DeviceAccess/DashBoard/Widget/Thermometer.vue'
    import ProgressBar from '@/views/content/Home/DeviceAccess/DashBoard/Widget/ProgressBar.vue'

    export default {
        components: { Gauge, Thermometer, ProgressBar},
        props: {
            device:{
                type: Object,
                default: null
            },
        }, 
        watch:{
            $props:{
                handler(){              
                    if(!this.initInfo){
                        setTimeout(() => {
                            this.MEM = this.device.systemInfo.ramUsage
                            this.CPU = this.device.systemInfo.cpuUsage
                            this.temp = this.device.systemInfo.coreTemperature
                            this.HardDisk = this.device.systemInfo.flashUsage
                            this.isItFire = this.device.systemInfo.fireAlert
                            this.isItWarning = this.device.systemInfo.failureAlert
                            this.isGatewayUnlocked = this.device.systemInfo.tamperSwitchAlert
                            this.initInfo = true

                            this.percentBattery = this.device.systemInfo.batteryLevel.toFixed(1) + 'V'
                            this.batteryLevel = this.device.systemInfo.batteryPercent.toFixed(1) + '%'

                            if(this.device.systemInfo.plugAC === true){
                                this.currentBattery = require("@/assets/icons/dashboard/baterryOn.svg")
                                this.baterryStatus = 'Conectado na rede AC'
                            }else if (this.device.systemInfo.plugAC === false){
                                this.currentBattery = require("@/assets/icons/dashboard/baterryOn.svg")
                                this.baterryStatus = 'Falha na alimentação da rede AC'
                            }
                        }, 500);
                    }else{
                        this.MEM = this.device.systemInfo.ramUsage
                        this.CPU = this.device.systemInfo.cpuUsage
                        this.temp = this.device.systemInfo.coreTemperature
                        this.HardDisk = this.device.systemInfo.flashUsage
                        this.isItFire = this.device.systemInfo.fireAlert
                        this.isItWarning = this.device.systemInfo.failureAlert
                        this.isGatewayUnlocked = this.device.systemInfo.tamperSwitchAlert

                        this.percentBattery = this.device.systemInfo.batteryLevel.toFixed(1) + 'V'
                            this.batteryLevel = this.device.systemInfo.batteryPercent.toFixed(1) + '%'

                        if(this.device.systemInfo.plugAC === true){
                            this.currentBattery = require("@/assets/icons/dashboard/baterryOn.svg")
                            this.baterryStatus = 'Conectado na rede AC'
                        }else if (this.device.systemInfo.plugAC === false){
                            this.currentBattery = require("@/assets/icons/dashboard/baterryOn.svg")
                            this.baterryStatus = 'Falha na alimentação da rede AC'
                        }
                    }
                        this.currentPlug = this.device.systemInfo.plugAC ? this.supplyStatusIcon.plug.on : this.supplyStatusIcon.plug.off
                },
                deep:true,
                immediate:true
            }, 
        },
        data() {
            return {
                MEM: 0,
                CPU: 0,
                temp: '0',
                initInfo: false,
                isItFire: false, 
                isItWarning: false,
                isGatewayUnlocked: false,
                currentBattery: require("@/assets/icons/dashboard/baterry.svg"),
                baterryStatus: '',
                percentBattery: '',
                batteryLevel: '',
                currentPlug: require("@/assets/icons/dashboard/plugOn.svg"),
                currentWifiIcon: require("@/assets/icons/dashboard/wifiOn.svg"),
                HardDisk:0,
                STATUS_COUNT_INDEX: STATUS_COUNT_INDEX,
                SYSTEM_INFO_INDEX: SYSTEM_INFO_INDEX,
                supplyStatusIcon: {
                    plug: {on: require("@/assets/icons/dashboard/plugOn.svg"), off: require("@/assets/icons/dashboard/plugOff.svg")},
                },
            }
        },
    }  
</script>

<style scoped>
    .percentBattery{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 50px;
        height: 25px;
        color: #1e2227
    }

    .levelBattery{
        position: absolute;
        top: 25px;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 50px;
        height: 25px;
        color: #1e2227
    }
    .redeStatus{
        flex: 1;
        display: flex;
        position: relative;
        margin-left: 10px;
        text-align: center;
        padding-bottom: 5%
    }
    .iconStatus{
        position: absolute;
        width: 100px;
        top: 45%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
    }

    .labelIcon{
        white-space: nowrap;
        align-self: flex-end;
        width: 100%;
    }

    .labelBottom{
        position: absolute;
        bottom: 13%;
        left: 0;
        width: 100%;
        text-align: center
    }
    .areaCentralInfo{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-between;
    }
    .areaInfo{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 30%;
    }

    .ResponsiveContentBoard{
        display: flex;
        flex-direction: column;
        font-family: sans-serif;
    }

    .areaInfoCard{
        display: flex;
        position: relative;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        margin-left: 10px;
        background-color: #1a1a22;
        border-radius: 6px
    }
   
    .flex{
        display: flex;
    }

    .flexColumn{
        display: flex;
        flex-direction: column;
    }

    .mainCardIlu{
        height: 45%;
    }

    .mainCardText{
        font-size: 18px;
        color: #FFFFFF;
        font-weight: 700;
        margin-top: 13px;
    }

    .mainCard{
        width: 15%;
        height: 100%;
        border: none;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        background-color: #1a1a22;
        padding: 0 10px;
        text-align: center;
    }

    .wrapMainCards{
        height: 40%;
        gap: 30px;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        margin: 0 auto;
    }

    .verifyCardIlu{
        height: 180%;
    }

    .verifyCardText{
        font-size: 100%;
        color: #FFFFFF;
        font-weight: 500;
        margin-left: 10px;
    }

    .verifyCardTextFire{
        font-size: 20px;
        color: #ff0000;
        font-weight: 500;
        margin-left: 10px;
    }

    .verifyCardTextWarning{
        font-size: 20px;
        color: #ffc22d;
        font-weight: 500;
        margin-left: 50px;
    }

    .VerifyCard{
        width: 55%;
        height: 25%;
        padding: 10px;
        background-color: #1a1a22;
        border: 2px solid #292D32;
        border-radius: 6px;
        align-items: center;
    }

    .wrapVerifyCards{
        gap: 10px;
        width: 100%;
        height: 35%;
        align-items: center;
        padding-top: 15px;
        margin: 0 auto;
    }

    /* Classes de animação */
    @keyframes changeColorRed {
        0% {
            background-color: #292d32;
            background-image: url("../../../../../assets/icons/fire.svg");
            background-position: 50% 10%;
            background-size: cover;
            background-repeat: no-repeat;
            background-size: 70% 70%;
        }

        50% {
            background-color: #ff0000;
            background-image: url("../../../../../assets/icons/fire.svg");
            background-position: 50% 10%;
            background-size: cover;
            background-repeat: no-repeat;
            background-size: 70% 70%;
        }

        100% {
            background-color: #292d32;
            background-image: url("../../../../../assets/icons/fire.svg");
            background-position: 50% 10%;
            background-size: cover;
            background-repeat: no-repeat;
            background-size: 70% 70%;
        }
    }

    @keyframes changeColorViolation {
        0% {
          background-color: #1a1a22;
        }

        50% {
          background-color: #ffc22d;
        }

        100% {
          background-color: #1a1a22;
        }
    }

    @keyframes changeColorYellow {
        0% {
          background-color: #1a1a22;
          background-image: url("../../../../../assets/icons/warning.svg");
          background-position: 50% 10%;
          background-size: cover;
          background-repeat: no-repeat;
          background-size: 70% 70%;
        }

        50% {
          background-color: #ffc22d;
          background-image: url("../../../../../assets/icons/warning_black.svg");
          background-position: 50% 10%;
          background-size: cover;
          background-repeat: no-repeat;
          background-size: 70% 70%;
        }

        100% {
          background-color: #1a1a22;
          background-image: url("../../../../../assets/icons/warning.svg");
          background-position: 50% 10%;
          background-size: cover;
          background-repeat: no-repeat;
          background-size: 70% 70%;
        }
    }

    @keyframes pulse {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.1);
        }

        100% {
          transform: scale(1);
        }
    }

    @keyframes changeVerifyFire {
        100% {
          border: 2px solid #ff2a2a;
        }
    }

    @keyframes changeVerifyWarning {
        100% {
          border: 2px solid #ffc22d;
        }
      }

    .verifyFire_animation {
        animation: changeVerifyFire 0.5s infinite alternate;
        background-image: url("../../../../../assets/icons/circleFire.svg");
        background-repeat: no-repeat;
        background-position: 15px 50%;
        background-size: 5% 80%;
    }

    .verifyFire_animation img {
        display: none;
    }

    .verifyFire_animation span {
        margin-left: 50px;
    }

    .fire_animation {
        animation: changeColorRed 2s infinite alternate, pulse 1s infinite;
    }

    .fire_animation img {
        display: none;
    }

    .fire_animation span {
        position: absolute;
        bottom: 23px;
    }

    .violation_animation {
        animation: changeColorViolation 2s infinite alternate, pulse 1s infinite;
    }

    .warning_animation {
        animation: changeColorYellow 2s infinite alternate, pulse 1s infinite alternate;
    }

    .warning_animation img {
        display: none;
    }

    .warning_animation span {
        position: absolute;
        bottom: 23px;
    }

    .verifyWarning_animation {
        animation: changeVerifyWarning 0.5s infinite alternate;
        background-image: url("../../../../../assets/icons/cirleWarning.svg");
        background-repeat: no-repeat;
        background-position: 15px 50%;
        background-size: 5% 80%;
    }

    .verifyWarning_animation img {
        display: none;
    }

    .verifyViolation_animation {
        animation: changeVerifyWarning 0.5s infinite alternate;
        background-image: url("../../../../../assets/icons/circlePadlock.svg");
        background-repeat: no-repeat;
        background-position: 15px 50%;
        background-size: 5% 80%;
    }

    .verifyViolation_animation img {
        display: none;
    }

    .padlockImg{
        position: relative;
        right: 20%;
        width: 40%;
        top: 8%;
    }

    @media(max-height: 900px), (max-width: 1600px){
        .iconStatus{
            width: 80px;
            margin-left: -40px;
            margin-top: -40px;
        }
    }
    @media(max-height: 820px), (max-width: 1450px){
        .iconStatus{
            width: 70px;
            margin-left: -35px;
            margin-top: -35px
        }
    }
    @media(max-height: 750px), (max-width: 825px){
        .iconStatus{
            width: 60px;
            margin-left: -30px;
            margin-top: -30px;
        }
    }
    
    @media(max-width: 1450px){
        .areaInfo{
            left:  7.5px;
        }

        .areaCentralInfo{
            right: 5px;
            top: 5px;
            bottom: 5px;
        }
        .rowTopPadding{
            padding: 5px 5px 2.5px 0px
        }
        .rowMiddlePadding{
            padding: 2.5px 5px 2.5px 0px
        }
        .rowBottomPadding{
            padding: 2.5px 5px 5px 0px
        }
        .areaInfoCard{
            padding-top: 5px;
            margin-left: 5px
        } 
    }

    @media(min-width: 1450px){
        .verifyCardIlu{
            height: 120%;
        }

        .verifyCardText{
            margin-left: 25px;
            font-size: 145%;
        }
        .percentBattery{
            top: -10px;
        }

        .verifyCardTextWarning{
            position: relative;
            left: 30px; 
        }

        .verifyCardTextFire{
            position: relative;
            left: 30px; 
        }
    }

    @media(max-height: 660px){
        .redeStatus{
            padding-bottom: 0%;
        }
    }
    @media (max-width: 600px) {
        .wrapMainCards{
            flex-direction: column;
            padding-top: 0;
            width: 60%;
            margin-top: 20px;
            align-items: center;
            height: fit-content;
        }

        .mainCard{
            width: 45%;
            height: 150px;
        }

        .ResponsiveContentBoard{
            width: 100vw;
        }

        .wrapVerifyCards{
            height: fit-content;
        }

        .VerifyCard{
            width: 100%;
        }
        .areaInfo{
            flex-direction: column;
            min-height: 700px;
            width: 80%;
            align-self: center;
            margin-top: -60px;
            gap: 20px;
        }

        .areaInfoCard{
            min-height: 150px;
        }

        .verifyFire_animation {
            background-size: 8% 80%;
        }

        .verifyWarning_animation{
            background-size: 8% 80%;
        }
        .verifyViolation_animation{
            background-size: 8% 80%;
        }

        .wrapVerifyCards{
            width: 90%;
        }

        .verifyCardIlu{
            height: 80%;
        }

        .padlockImg{
            right: 0;
        }
    } 
</style>