<template>
    <div class="areaRef" ref="mainAreaRef">
        <div class="alertTableArea">
            <vue-virtual-table
                :config="tableConfig"
                :data="currentAlertList"
                :bordered="false"
                :hoverHighlight="false"
                :height="tableHeight - offSetTableHeight"
                :minWidth="800"
                :itemHeight="60">
            </vue-virtual-table>
        </div>
        <div class="statusInfo textAverage">
            <div v-if="currentTab.id != 5" class="info">Ativados:</div>
            <div v-else class="info">Desabilitados:</div>
            <div class="infoValue textNormal" :style="{backgroundColor: currentTab.color}">{{alertActivated}}</div>
            <div v-if="currentTab.id != 5" class="info">Silenciados:</div>
            <div v-if="currentTab.id != 5" class="infoValue silencedColor textNormal">{{alertSilenced}}</div>
        </div>
        <div class="btnArea">
            <button @click="generatePDF()" class="btn btnAverageSize btnTypeSave">Gerar PDF</button>
        </div>
    </div>
</template>

<script>
import {STATUS_COUNT_INDEX} from '@/sys/Model/Device/Device';
import VueVirtualTable from '@/assets/localPackages/vue-virtual-table'
import firebeeIcon from '@/assets/icons/firebeeIcon.png';
import { jsPDF } from "jspdf";
require('jspdf-autotable');


export default {
    components:{ VueVirtualTable },
    props: {
        currentTab: Object,
        device:{
            type: Object,
            default: null
        }
    },
    methods:{
        generatePDF(){
            const pdf = new jsPDF();

            let self = this
            var header = function(){
                pdf.addImage(firebeeIcon,'PNG', 4, 6, 15, 15)
                pdf.setFont(undefined, 'bold')
                pdf.setFontSize(18)
                pdf.text('Relatório dos Alertas Atuais', 60, 15)
                pdf.line(5, 26, 205, 26)
                pdf.setFont(undefined, 'bold')
                pdf.setFontSize(10)
                pdf.text(15, 31, 'Tipo do Alerta: ')
                pdf.setFont(undefined, 'normal')
                pdf.text(43, 31, self.currentTab.name)
                pdf.setFont(undefined, 'bold')
                pdf.text(100, 31, 'Ativados:')
                pdf.setFont(undefined, 'normal')
                pdf.text(118, 31, self.alertActivated.toString())
                pdf.setFont(undefined, 'bold')
                if(self.currentTab.id != 5){
                    pdf.text(160, 31, 'Silenciados:')
                    pdf.setFont(undefined, 'normal')
                    pdf.text(183, 31, self.alertSilenced.toString())
                }
                pdf.line(5, 40, 205, 40)
                pdf.setFont(undefined, 'bold')
                pdf.text(15, 37,'Exportado: ')
                pdf.setFont(undefined, 'normal')
                pdf.text(36, 37, new Date().toLocaleString())
                pdf.line(5, 285, 205, 285)
                pdf.setFontSize(9)
                pdf.text(15, 291, 'www.firebee.com.br')
            }

            var row = []
            var col = []

            if(this.currentTab.id != 5){
                col = ["Dispositivo", "Tipo do Dispositivo", "Alerta", "Planta", "Local", "Data", "Status"]
                this.currentAlertList.forEach(item => {
                    row.push([item.name, item.type, item.alert, item.zone, item.description, item.date, item.status])
                })
            }else{
                col = ["Dispositivo", "Tipo do Dispositivo", "Motivo", "Planta", "Local", "Data"]
                this.currentAlertList.forEach(item => {
                    row.push([item.name, item.type, item.reason, item.zone, item.description, item.date])
                })
            }

            pdf.autoTable({
                columns: col, body: row, margin: {
                    left: 5,
                    top: 44
                },
                didDrawPage: header,
                headStyles: {
                    fillColor: [227, 7, 32],
                    textColor: [255, 255, 255],
                    halign: 'center'
                },
                bodyStyles: {
                    halign: 'center'
                },
                tableWidth: 200
            })

            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(9);
                pdf.text('Pagina ' + i + ' de ' + totalPages, 90, 291);
            }
    
            pdf.save("Relatorio " + new Date().toLocaleString() + ".pdf")
        },
        layoutAlertTable(size){
            if(size == 'large'){
                return [
                    {name: 'Dispositivo', prop: 'name', width: 200},
                    {name: 'Tipo do Dispositivo', prop: 'type'},
                    {name: 'Alerta', prop: 'alert'},
                    {name: 'Planta', prop: 'zone'},
                    {name: 'Local', prop: 'description'},
                    {name: 'Data', prop: 'date', width: 110},
                    {name: 'Status', prop: 'status', width: 100}
                ]
            }else if(size == 'average'){
                return [
                    {name: 'Dispositivo', prop: 'name', width: 160},
                    {name: 'Tipo do Dispositivo', prop: 'type'},
                    {name: 'Alerta', prop: 'alert'},
                    {name: 'Planta', prop: 'zone'},
                    {name: 'Local', prop: 'description'},
                    {name: 'Data', prop: 'date', width: 90},
                    {name: 'Status', prop: 'status', width: 80}
                ]
            }else{
                return [
                    {name: 'Dispositivo', prop: 'name', width: 120},
                    {name: 'Tipo do Dispositivo', prop: 'type'},
                    {name: 'Alerta', prop: 'alert'},
                    {name: 'Planta', prop: 'zone'},
                    {name: 'Local', prop: 'description'},
                    {name: 'Data', prop: 'date', width: 70},
                    {name: 'Status', prop: 'status', width: 70}
                ]
            }
        },
        layoutMaintenanceTable(size){
            if(size == 'large'){
                return [
                    {name: 'Dispositivo', prop: 'name', width: 200},
                    {name: 'Tipo do Dispositivo', prop: 'type'},
                    {name: 'Motivo', prop: 'reason'},
                    {name: 'Planta', prop: 'zone'},
                    {name: 'Local', prop: 'description'},
                    {name: 'Data', prop: 'date', width: 110}
                ]
            }else if(size == 'average'){
                return [
                    {name: 'Dispositivo', prop: 'name', width: 160},
                    {name: 'Tipo do Dispositivo', prop: 'type'},
                    {name: 'Motivo', prop: 'reason'},
                    {name: 'Planta', prop: 'zone'},
                    {name: 'Local', prop: 'description'},
                    {name: 'Data', prop: 'date', width: 90}
                ]
            }else{
                return [
                    {name: 'Dispositivo', prop: 'name', width: 120},
                    {name: 'Tipo do Dispositivo', prop: 'type'},
                    {name: 'Motivo', prop: 'reason'},
                    {name: 'Planta', prop: 'zone'},
                    {name: 'Local', prop: 'description'},
                    {name: 'Data', prop: 'date', width: 70}
                ]
            }
        },
        onResize(){
            if(this.currentTab.id != 0){
                this.tableHeight = this.$refs.mainAreaRef.clientHeight
                if(this.$refs.mainAreaRef.clientWidth > 1040){
                    if(this.currentTab.id == 5){
                        this.tableConfig = this.layoutMaintenanceTable('large')
                    }else{
                        this.tableConfig = this.layoutAlertTable('large')
                    }
                } else if(this.$refs.mainAreaRef.clientWidth <= 1040 && this.$refs.mainAreaRef.clientWidth > 850){
                    if(this.currentTab.id == 5){
                        this.tableConfig = this.layoutMaintenanceTable('average')
                    }else{
                        this.tableConfig = this.layoutAlertTable('average')
                    }
                } else {
                    if(this.currentTab.id == 5){
                        this.tableConfig = this.layoutMaintenanceTable('small')
                    }else{
                        this.tableConfig = this.layoutAlertTable('small')
                    }
                }
            }
        },
        updateList(){
            this.currentAlertList = []
            if(this.currentTab.id == 1){
                this.alertSilenced = this.device.status.fire.silenceCount
                this.alertActivated = this.device.status.fire.count - this.alertSilenced
                for (const alert of this.device.status.fire.list) {
                    this.currentAlertList.push({name: alert[1], type: alert[2], alert: alert[5], zone: alert[4], description: alert[3], date: alert[8], status: alert[6] == 2 ? 'Ativado' : 'Silenciado'})
                }
            }else if(this.currentTab.id == 2){
                this.alertSilenced = this.device.status.failure.silenceCount
                this.alertActivated = this.device.status.failure.count - this.alertSilenced
                for (const alert of this.device.status.failure.list) {
                    this.currentAlertList.push({name: alert[1], type: alert[2], alert: alert[5], zone: alert[4], description: alert[3], date: alert[8], status: alert[6] == 2 ? 'Ativado' : 'Silenciado'})
                }
            }else if(this.currentTab.id == 3){
                this.alertSilenced = this.device.status.lowBattery.silenceCount
                this.alertActivated = this.device.status.lowBattery.count - this.alertSilenced
                for (const alert of this.device.status.lowBattery.list) {
                    this.currentAlertList.push({name: alert[1], type: alert[2], alert: alert[5], zone: alert[4], description: alert[3], date: alert[8], status: alert[6] == 2 ? 'Ativado' : 'Silenciado'})
                }
            }else if(this.currentTab.id == 4){
                this.alertSilenced = this.device.status.supervision.silenceCount
                this.alertActivated = this.device.status.supervision.count - this.alertSilenced
                for (const alert of this.device.status.supervision.list) {
                    this.currentAlertList.push({name: alert[1], type: alert[2], alert: alert[5], zone: alert[4], description: alert[3], date: alert[8], status: alert[6] == 2 ? 'Ativado' : 'Silenciado'})
                }
            }else if(this.currentTab.id == 5){
                this.alertActivated = this.device.status.disable.count
                for (const alert of this.device.status.disable.list) {
                    this.currentAlertList.push({name: alert[1], type: alert[2], reason: alert[5], zone: alert[4], description: alert[3], date: alert[8]})
                }
            }
        }
    },
    mounted(){
        this.onResize()
        this.updateList()
        window.addEventListener('resize', this.onResize)
    },
    destroyed(){
        window.removeEventListener('resize', this.onResize)
    },
    watch:{
        currentTab(){
            if(this.currentTab.id != 0){
                this.onResize()
                this.updateList()
            }
        }
    },
    data() {
        return {
            alertActivated: 0,
            alertSilenced: 0,
            tableConfig: [],
            currentAlertList: [],
            tableHeight: 0,
            offSetTableHeight: 80
        }
    }
}
</script>

<style>
    .areaRef{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0
    }
    .alertTableArea{
        position: absolute;
        top: 10px;
        right: 10px;
        left: 10px;
        background-color: #1e2227;
    }
    .main-scroll{
        font-family: Arial, Helvetica, sans-serif;
        color: white;
        font-size: 19px;
    }
    .statusInfo{
        position: absolute;
        bottom: 15px;
        left: 30px;
        height: 60;
    }
    .info{
        display: inline-block;
    }
    .infoValue{
        display: inline-block;
        padding: 8px 16px 8px 16px;
        border-radius: 6px 6px 6px 6px;
        margin: 0 20px 0 5px
    }
    .silencedColor{
        background-color: #1a1a22
    }
    .btnArea{
        position: absolute;
        right: 15px;
        bottom: 10px;
    }
    @media(max-width: 1450px){
        .main-scroll{
            font-size: 16px;
        }
    }
    @media(max-width: 825px){
        .main-scroll{
            font-size: 12px;
        }
    }
</style>