<template>
    <div class="mainAreaOthers">
        <div v-if="isCentralFirebee()" class="iconArea">
            <img v-if="isCLE()" class="centralIcons" src="@/assets/icons/AccessType/cle.svg"/>
            <img v-else class="centralIcons" src="@/assets/icons/AccessType/clx.svg"/>
        </div>
        <div v-else class="marginToInfo"></div>
        <div class="nameArea textNormal">{{device.client.name}}</div>
        <div class="aboutForm textSmall">
            <div class="aboutColumn textGray alignRight">
                <div class="aboutRow">Unidade:</div>
                <div class="aboutRow">Descrição:</div>
            </div>
            <div class="aboutColumn">
                <div class="aboutRow">{{device.unity.name}}</div>
                <div class="aboutRow">{{device.description}}</div>
            </div>
        </div>
        <div class="normalLabel textSmall">{{device.unity.city}} - {{device.unity.state}}</div>
        <template v-if="device.type.id == CENTRAL_TYPE.GATEWAY">
            <div class="normalLabel textNormal deviceStatus">Status da Central</div>
            <div class="normalLabel textSmall textGray deviceStatus">Sem informações dos status</div>
        </template>
        <template v-else>
            <div class="marginToServices"></div>
            <div class="normalLabel textSmall">Serviços Disponíveis</div>
            <div class="services">
                <router-link class="service" :to="{ name:'SSH', params: { device:device }}" :class="{disableOption: !showSSH()}">
                    <img v-if="showSSH()" class="servicesIcon" src="@/assets/icons/AccessType/backgroundBtn.svg"/>
                    <img v-else class="servicesIcon" src="@/assets/icons/AccessType/backgroundBtnDisable.svg"/>
                    <img class="serviceIcon" src="@/assets/icons/AccessType/ssh.svg"/>
                    <div class="labelService textSmall">SSH</div>
                </router-link>
                <router-link class="service" :to="{ name:'RDP', params: { device:device }}" :class="{disableOption: !showRDP()}">
                    <img v-if="showRDP()" class="servicesIcon" src="@/assets/icons/AccessType/backgroundBtn.svg"/>
                    <img v-else class="servicesIcon" src="@/assets/icons/AccessType/backgroundBtnDisable.svg"/>
                    <img class="serviceIcon" src="@/assets/icons/AccessType/rdp.svg"/>
                    <div class="labelService textSmall">RDP</div>
                </router-link>
            </div>
        </template>
    </div>
</template>

<script>
import {Services} from '@/sys/Model/Permissions/Services'
import {AccessLevel} from '@/sys/Model/Permissions/AccessLevel'
import {CENTRAL_TYPE} from '@/sys/Model/Device/Device';

export default {
    props:{
        device: Object,
        accessLevel: String,
        services: Array
    },
    methods:{
        compareService(service) {
            let services = this.services;
            for(let i=0; i < services.length; i++) {
                if(services[i].$numberInt == service) {
                    return true;
                }
            }
            return false;
        },
        isCentralFirebee(){
            return this.device.type.id != CENTRAL_TYPE.GATEWAY
        },
        isCLE(){
            return this.device.type.id == CENTRAL_TYPE.CLE
        },
        showSSH(){
            return this.accessLevel <= AccessLevel.DESENVOLVIMENTO && this.compareService(Services.SSH)
        },
        showRDP(){
            return this.accessLevel <= AccessLevel.CLIENTE && this.accessLevel != AccessLevel.PRODUCAO && this.accessLevel != AccessLevel.VENDAS
        },
    },
    data(){
        return{
            CENTRAL_TYPE: CENTRAL_TYPE
        }
    }
}
</script>

<style scoped>
    .mainAreaOthers{
        display: flex;
        flex-direction: column;
    }
    .iconArea{
        margin: 0 auto;
    }
    .centralIcons{
        height: 230px;
    }
    .nameArea{
        margin-top: 20px;
        text-align: center;
    }
    .normalLabel{
        text-align: center;
    }
    .deviceStatus{
        margin-top: 40px;
    }
    .aboutForm{
        margin-top: 20px;
        width: 800px;
        align-self: center;
    }
    .aboutLogo{
        width: 550px;
        margin: 160px 0 20px 0;
    }
    .aboutColumn{
        display: inline-block;
        padding: 0 10px;
        width: 50%;
    }
    .aboutRow{
        height: 38px;
    }
    .textGray{
        color: gray;
    }
    .alignRight{
        text-align: right;
    }
    .marginToInfo{
        height: 110px;
    }
    .marginToServices{
        height: 90px;
    }
    .services{
        height: 200px;
        width: 320px;
        align-self: center;
    }
    .service{
        position: relative;
        height: 100%;
        width: 50%;
        display: inline-block;
    }
    .servicesIcon{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 160px;
        margin: auto;
    }
    .serviceIcon{
        position: absolute;
        top: 0;
        bottom: 30px;
        right: 0;
        left: 0;
        height: 50px;
        margin: auto;
    }
    .labelService{
        position: absolute;
        bottom: 35px;
        right: 0;
        left: 0;
        height: 50px;
        margin: auto;
        text-align: center;
    }
    .disableOption{
        pointer-events: none;
    }
    @media(max-width: 1450px){
        .centralIcons{
            height: 160px;
        }
        .marginToInfo{
            height: 40px;
        }
        .marginToServices{
            height: 70px;
        }
        .services{
            height: 140px;
            width: 220px;
        }
        .servicesIcon{
            height: 110px;
        }
        .serviceIcon{
            bottom: 30px;
            height: 35px;
        }
        .labelService{
            bottom: 25px;
            height: 30px;
        }
        .aboutRow{
            height: 28px;
        }
    }
</style>