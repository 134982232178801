<template>
    <div class="areaRef" ref="mainAreaRef" v-if="isThereList">
        <div class="alertTableArea">
            <vue-virtual-table
                :config="tableConfig"
                :data="currentAlertList"
                :bordered="false"
                :hoverHighlight="false"
                :height="tableHeight - offSetTableHeight"
                :minWidth="300"
                :itemHeight="60">
            </vue-virtual-table>
        </div>
        <div class="btnArea">
            <button @click="generatePDF()" class="btn btnAverageSize btnTypeSave">Gerar PDF</button>
        </div>
    </div>
    <div v-else-if="!isThereList" class="noListDiv flexColumn">
        <img src="../../../../../assets/icons/emptyFolder.svg" alt="empty_folder" class="noListDivImg">
        <span class="noListDivText">Sem registros de alerta</span>
    </div>
</template>

<script>
import {STATUS_COUNT_INDEX} from '@/sys/Model/Device/Device';
import VueVirtualTable from '@/assets/localPackages/vue-virtual-table'
import firebeeIcon from '@/assets/icons/firebeeIcon.png';
import { jsPDF } from "jspdf";
require('jspdf-autotable');


export default {
    components:{ VueVirtualTable },
    props: {
        currentTab: Object,
        device:{
            type: Object,
            default: null
        }
    },
    methods:{
        generatePDF(){
            const pdf = new jsPDF();

            let self = this
            var header = function(){
                pdf.addImage(firebeeIcon,'PNG', 4, 6, 15, 15)
                pdf.setFont(undefined, 'bold')
                pdf.setFontSize(18)
                pdf.text(`Relatório dos Alertas de ${self.currentTab.name}`, 60, 15)
                pdf.line(5, 26, 205, 26)
                pdf.setFont(undefined, 'bold')
                pdf.setFontSize(10)
                pdf.text(15, 31, 'Tipo do Alerta: ')
                pdf.setFont(undefined, 'normal')
                pdf.text(43, 31, self.currentTab.name)
                pdf.setFont(undefined, 'bold')
                pdf.line(5, 40, 205, 40)
                pdf.setFont(undefined, 'bold')
                pdf.text(15, 37,'Exportado: ')
                pdf.setFont(undefined, 'normal')
                pdf.text(36, 37, new Date().toLocaleString())
                pdf.line(5, 285, 205, 285)
                pdf.setFontSize(9)
                pdf.text(15, 291, 'www.firebee.com.br')
            }

            var row = []
            const col = ['ID', 'Ativado em', 'Desativado em'];
            this.currentAlertList.forEach(item => {
                row.push([item.id, item.activedOn, item.deactivatedOn])
            })
            
            pdf.autoTable({
                columns: col, body: row, margin: {
                    left: 5,
                    top: 44
                },
                didDrawPage: header,
                headStyles: {
                    fillColor: [227, 7, 32],
                    textColor: [255, 255, 255],
                    halign: 'center'
                },
                bodyStyles: {
                    halign: 'center'
                },
                tableWidth: 200
            })

            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(9);
                pdf.text('Pagina ' + i + ' de ' + totalPages, 90, 291);
            }
    
            pdf.save("Relatorio " + new Date().toLocaleString() + ".pdf")
        },
        onResize(){
            // Update screen size based on window width
            if (window.innerWidth <= 600) {
                this.tableConfig = [
                    {name: 'ID', prop: 'id', width: 60},
                    {name: 'Ativado em', prop: 'activedOn',eClass: { twoLines: "true" }},
                    {name: 'Desativado em', prop: 'deactivatedOn',eClass: { twoLines: "true" }}
                ]
            } else {
                this.tableConfig = [
                    {name: 'ID', prop: 'id', width: 180},
                    {name: 'Ativado em', prop: 'activedOn'},
                    {name: 'Desativado em', prop: 'deactivatedOn'}
                ]
            }
        },
        updateList(){
            this.currentAlertList = []
            if(this.currentTab.id == 1){
                if(this.device.systemInfo.alertList.fireList[0]){
                    this.isThereList = true;   
                                    
                    for (const alert of this.device.systemInfo.alertList.fireList) {
                        this.currentAlertList.push({id: alert[0], activedOn: this.formatDate(alert[1]), deactivatedOn: this.formatDate(alert[2])})
                    }
                }else{
                    this.isThereList = false;  
                }
            }else if(this.currentTab.id == 2){
                if(this.device.systemInfo.alertList.failureList[0]){
                    this.isThereList = true;
                    for (const alert of this.device.systemInfo.alertList.failureList) {
                        this.currentAlertList.push({id: alert[0], activedOn: this.formatDate(alert[1]), deactivatedOn: this.formatDate(alert[2])})
                    }
                }else{
                    this.isThereList = false;
                }
            }else if(this.currentTab.id == 3){
                if(this.device.systemInfo.alertList.tsList[0]){
                    this.isThereList = true;
                    for (const alert of this.device.systemInfo.alertList.tsList) {
                        this.currentAlertList.push({id: alert[0], activedOn: this.formatDate(alert[1]), deactivatedOn: this.formatDate(alert[2])})
                    }
                }else{
                    this.isThereList = false;
                }
            }
        },
        formatDate(dateString) {
            if (dateString === undefined || dateString === null || dateString === "NULL") {
                return "Ativo";
            }

            const [date, time] = dateString.split('--');
                
            const [day, month, year] = date.split('-');
                
            return `${day}/${month}/${year}\n${time}`;
        }
    },
    mounted(){
        this.onResize()
        this.updateList()
        window.addEventListener('resize', this.onResize)
    },
    destroyed(){
        window.removeEventListener('resize', this.onResize)
    },
    watch:{
        currentTab(){
            if(this.currentTab.id != 0){
                this.onResize()
                this.updateList()
            };
        },
        device: {
            handler(newDevice, oldDevice) {
                this.updateList();
            },
            deep: true
        },
    },
    data() {
        return {
            alertActivated: 0,
            alertSilenced: 0,
            tableConfig: [],
            currentAlertList: [],
            tableHeight: 0,
            offSetTableHeight: 80,
            isThereList: false
        }
    }
}
</script>

<style>
    .areaRef{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0
    }
    .alertTableArea{
        position: absolute;
        top: 10px;
        right: 10px;
        left: 10px;
        background-color: #1e2227;
    }
    .main-scroll{
        font-family: Arial, Helvetica, sans-serif;
        color: white;
        font-size: 19px;
    }
    .statusInfo{
        position: absolute;
        bottom: 15px;
        left: 30px;
        height: 60;
    }
    .info{
        display: inline-block;
    }
    .infoValue{
        display: inline-block;
        padding: 8px 16px 8px 16px;
        border-radius: 6px 6px 6px 6px;
        margin: 0 20px 0 5px
    }
    .silencedColor{
        background-color: #1a1a22
    }
    .btnArea{
        position: absolute;
        right: 15px;
        bottom: 10px;
    }
    .flexColumn{
        display: flex;
        flex-direction: column;
    }
    .noListDiv{
        height: 60%;
        width: fit-content;
        margin: 10% auto;
        font-family: sans-serif;
    }
    .noListDivImg{
        height: 50%;
    }
    .noListDivText{
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
        margin-top: 15%;
    }
    .twoLines{
        white-space: pre-line;
        line-height: 20px;
    }
    @media(max-width: 1450px){
        .main-scroll{
            font-size: 16px;
        }
    }
    @media(max-width: 825px){
        .main-scroll{
            font-size: 12px;
        }
    }
</style>